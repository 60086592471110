<template>
    <div class="w-full">
        <div v-if="isMobile" class="flex items-center w-full">
            <a-button
                v-if="dropActions && dropActions.change_status && currentStatus"
                type="primary"
                class="md:px-6 lg:px-6"
                block
                size="large"
                :loading="loading"
                @click="changeStatus(currentStatus)">
                {{ currentStatus.btn_title ? currentStatus.btn_title : currentStatus.name }}
            </a-button>
            <div class="w-full" v-else></div>
            <a-button
                type="primary"
                :loading="actionLoading"
                :disabled="loading"
                size="large"
                class="ml-1 dots_btn"
                flaticon
                icon="fi-rr-menu-dots-vertical"
                @click="openDrawer" />
            <ActivityDrawer v-model="visible">
                <ActivityItem
                    v-if="!dropActions && actionLoading"
                    key="menu_loader">
                    <div class="w-full flex justify-center">
                        <a-spin size="small" />
                    </div>
                </ActivityItem>
                <template v-if="dropActions">
                    <template v-if="!item.is_auction && dropActions.change_status && showStatus && cStatusFiltered.length">
                        <ActivityItem
                            v-if="statusLoader"
                            key="loader">
                            <a-spin size="small" />
                        </ActivityItem>
                        <template v-else>
                            <ActivityItem
                                v-for="status in cStatusFiltered"
                                :key="status.code"
                                @click="changeStatus(status)">
                                <div v-if="status.color !== 'default'" class="mob_badge">
                                    <a-badge :color="status.color" />
                                </div>
                                {{ status.btn_title ? status.btn_title : status.name }}
                            </ActivityItem>
                        </template>
                    </template>
                    <ActivityItem
                        v-if="dropActions.add_subtask && item.level < 3" 
                        key="add"
                        @click="addSubtaskFunc()">
                        <i class="fi fi-rr-folder-tree mr-2"></i>
                        {{$t('task.add_subtask')}}
                    </ActivityItem>
                    <ActivityItem
                        v-if="dropActions.share"
                        key="share"
                        @click="share()">
                        <i class="fi fi-rr-share mr-2"></i>
                        {{$t('task.share_to_chat')}}
                    </ActivityItem>
                    <ActivityItem
                        v-if="dropActions.edit" 
                        key="edit"
                        @click="editFull()">
                        <i class="fi fi-rr-edit mr-2"></i>
                        {{$t('task.edit')}}
                    </ActivityItem>
                    <ActivityItem
                        v-if="dropActions.copy"
                        key="copy"
                        @click="copyFunc()">
                        <i class="fi fi-rr-copy-alt mr-2"></i>
                        {{$t('task.copy')}}
                    </ActivityItem>
                    <ActivityItem 
                        v-if="dropActions.delete && !item.children_count"
                        key="delete" 
                        @click="deleteFunc()">
                        <div class="text-red-500">
                            <i class="fi fi-rr-trash mr-2"></i>
                            {{$t('task.remove')}}
                        </div>
                    </ActivityItem>
                </template>
            </ActivityDrawer>
        </div>
        <a-button-group v-else class="w-full md:w-auto lg:w-auto mr-2">
            <a-button
                v-if="dropActions && dropActions.change_status && currentStatus"
                type="primary"
                class="md:px-6 lg:px-6"
                :loading="loading"
                @click="changeStatus(currentStatus)">
                {{ currentStatus.btn_title ? currentStatus.btn_title : currentStatus.name }}
            </a-button>
            <a-dropdown 
                :trigger="dropTrigger" 
                :disabled="actionLoading"
                :getPopupContainer="getPopupContainer">
                <a-button
                    type="primary"
                    :loading="actionLoading"
                    flaticon
                    icon="fi-rr-menu-dots-vertical" />
                <a-menu slot="overlay">
                    <template v-if="dropActions">
                        <a-menu-item 
                            v-if="statusLoader"
                            key="loader"
                            class="flex justify-center">
                            <a-spin size="small" />
                        </a-menu-item>
                        <template v-if="!item.is_auction && dropActions.change_status && cStatusFiltered.length">
                            <a-menu-item 
                                v-for="status in cStatusFiltered"
                                :key="status.code"
                                class="flex items-center"
                                @click="changeStatus(status)">
                                <a-badge :color="status.color" />
                                {{ status.btn_title ? status.btn_title : status.name }}
                            </a-menu-item>
                            <a-menu-divider />
                        </template>

                        <a-menu-item 
                            v-if="dropActions.add_subtask && item.level < 3" 
                            key="add"
                            class="flex items-center"
                            @click="addSubtaskFunc()">
                            <i class="fi fi-rr-folder-tree mr-2"></i>
                            {{$t('task.add_subtask')}}
                        </a-menu-item>
                        <a-menu-item 
                            v-if="dropActions.add_task" 
                            key="addTask"
                            class="flex items-center"
                            @click="addTaskFunc()">
                            <i class="fi fi-rr-list-check mr-2"></i>
                            {{$t('task.add_task')}}
                        </a-menu-item>
                        <a-menu-item
                            v-if="dropActions.share"
                            key="share"
                            class="flex items-center"
                            @click="share()">
                            <i class="fi fi-rr-share mr-2"></i>
                            {{$t('task.share_to_chat')}}
                        </a-menu-item>
                        <a-menu-item 
                            v-if="dropActions.edit && myTask" 
                            key="edit" 
                            class="flex items-center"
                            @click="editFull()">
                            <i class="fi fi-rr-edit mr-2"></i>
                            {{$t('task.edit')}}
                        </a-menu-item>
                        <a-menu-item
                            v-if="dropActions.edit" 
                            key="copy"
                            class="flex items-center"
                            @click="copyFunc()">
                            <i class="fi fi-rr-copy-alt mr-2"></i>
                            {{$t('task.copy')}}
                        </a-menu-item>
                        <template v-if="dropActions.delete && !item.children_count">
                            <a-menu-divider />
                            <a-menu-item
                                class="text-red-500 flex items-center" 
                                key="delete" 
                                @click="deleteFunc()">
                                <i class="fi fi-rr-trash mr-2"></i>
                                {{$t('task.remove')}}
                            </a-menu-item>
                        </template>
                    </template>
                </a-menu>
            </a-dropdown>
        </a-button-group>
    </div>
</template>


<script>
import mixins from './mixins.js'
import { ActivityItem, ActivityDrawer } from '@/components/ActivitySelect'
export default {
    mixins: [
        mixins
    ],
    components: {
        ActivityItem,
        ActivityDrawer
    },
    computed: {
        cStatusFiltered() {
            if(this.filteredList?.length && this.item) {
                let filtered = this.filteredList.filter(f => !f.is_complete && f.code !== this.item.status.code && !f.depends?.length)

                if(this.isAuthor || (this.isLogistic && this.isOperator)) {
                    const find = this.filteredList.filter(f => f.is_complete)
                    if(find?.length)
                        filtered = filtered.concat(find)
                }

                return filtered
            } else
                return []
        },
        currentStatus() {
            if(this.filteredList?.length && this.item) {
                if(this.item.status.is_complete)
                    return null
                else {
                    const find = this.filteredList.find(f => f.depends?.find(fn => fn === this.item.status.code))
                    if(find?.is_complete) {
                        if(this.isAuthor)
                            return find ? find : null
                        else
                            return null
                    } else
                        return find ? find : null
                }
            } else
                return null
        },
        isMobile() {
            return this.$store.state.isMobile
        }
    },
    data() {
        return {
            visible: false,
        }
    },
    created() {
        this.getTaskActions()
    },
    methods: {
        openDrawer() {
            this.visible = true
        }
    }
}
</script>

<style lang="scss" scoped>
.edit_icon_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    
    line-height: 100%;
    font-size: 1rem;
}
.mob_badge{
    width: 22px;
    height: 22px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    &::v-deep{
        .ant-badge{
            .ant-badge-status-dot{
                width: 10px;
                height: 10px;
            }
        }
    }
}
</style>