<template>
    <a-drawer
        :title="$t('task.select_task')"
        class="task_select_drawer"
        :width="isMobile ? '100%' : 460"
        :zIndex="1200"
        :destroyOnClose="true"
        :visible="taskDrawer"
        @close="closeHandler()">
        <div class="drawer_header">
            <a-input-search
                :loading="searchLoading"
                v-model="search"
                @input="onSearch"
                :placeholder="$t('task.task_name')" />
        </div>
        <div 
            class="drawer_body" 
            ref="subtask_scroll">
            <div class="drawer_scroll">
                <!-- <ul class="bordered-items">
                    <li 
                        v-for="(task, index) in taskList" 
                        :key="index"
                        class="cursor-pointer item px-3 py-3 flex items-center justify-between" 
                        @click="selectTask(task)" >
                        <div>
                            <div class="name blue_color">
                                {{task.name}}
                            </div>
                            <div class="mt-1 flex items-center">
                                <div class="users_info flex items-center mr-5">
                                    <div  class="flex" >
                                        <Profiler
                                            :user="task.owner"
                                            :showUserName="false"
                                            :avatarSize="18"
                                            :getPopupContainer="getPopupContainer" />
                                    </div>
                                    <a-icon 
                                        type="right" 
                                        class="mx-2 text-xs" />
                                    <div class="flex">
                                        <Profiler
                                            :user="task.operator"
                                            :showUserName="false"
                                            :avatarSize="18"
                                            :getPopupContainer="getPopupContainer" />
                                    </div>
                                </div>
                                <DeadLine 
                                    v-if="task.dead_line"
                                    wrapperClass="text-xs" 
                                    :date="task.dead_line"  
                                    :taskStatus="task.status" />
                            </div>
                            <div class="mt-2">
                                <TaskStatus :status="task.status" />
                            </div>
                        </div>
                        <div class="pl-2">
                            <a-radio :checked="checkSelected(task)" />
                        </div>

                        
                    </li>
                </ul> -->
                <div class="p-3 max-w-full">
                    <div
                        class="flex items-center" 
                        v-for="item in taskList" 
                        :key="item.id">
                        <KanbanItem 
                            :item="item" 
                            selectingSubtask
                            class="w-full"
                            :selectFunction="selectTask"
                            :isScrolling="false"
                            :myTaskEnabled="false"
                            :showStatus="true" /> 
                    </div>
                </div>

                <Loader
                    class="chat__active-chats"
                    rowClass="px-2 lg:px-4 py-3"
                    v-if="loading && page === 1"
                    titleFull
                    hideParagraph
                    :skeletonRow="7" />
                <infinite-loading 
                    ref="userInfinite" 
                    @infinite="getTaskList" 
                    v-bind:distance="10">
                    <div slot="spinner">
                        <a-spin v-if="page !== 1" />
                    </div>
                    <div slot="no-more"></div>
                    <div slot="no-results"></div>
                </infinite-loading>
            </div>
        </div>
        <div class="drawer_footer flex items-center">
            <a-button
                block
                type="ui"
                ghost
                class="px-8"
                @click="closeHandler()">
                {{$t('task.close')}}
            </a-button>
        </div>
    </a-drawer>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import Loader from '../Loader.vue'
import DeadLine from '../DeadLine.vue'
import TaskStatus from '../TaskStatus'
import KanbanItem from '../Kanban/Item.vue'

let timer;
export default {
    components: {
        InfiniteLoading,
        Loader,
        // DeadLine,
        // TaskStatus
        KanbanItem
    },
    props: {
        value: {
            type: Object
        },
        taskDrawer: {
            type: Boolean,
            default: false
        },
        closeHandler: {
            type: Function,
            required: true
        },
        filters: {
            type: Object,
            default: null
        },
        selectParentTask: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            searchLoading: false,
            taskList: [],
            search: '',
            scrollStatus: true,
            page: 0,
            loading: false
        }
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobile
        }
    },
    watch:{
        filters(){
            this.onSearch()
        }
    },
    methods: {
        getPopupContainer() {
            return this.$refs['subtask_scroll']
        },
        onSearch() {
            clearTimeout(timer)
            timer = setTimeout(() => {
                this.scrollStatus = true
                this.page = 0
                this.taskList = []
                this.getTaskList()
            }, 800)
        },
        checkSelected(task) {
            if(this.value) {
                if(task.id === this.value.id)
                    return true
                else
                    return false
            } else
                return false
        },
        selectTask(item) {
            this.$emit('input', item)
            this.closeHandler()
            this.selectParentTask(item)
        },
        async getTaskList($state = null) {
            if(!this.loading && this.scrollStatus && this.taskDrawer) {
                try {
                    this.loading = true
                    this.page = this.page+1
                    let params = {
                        page_size: 15,
                        page: this.page,
                        parent: 'all',
                        ...this.filters
                    }

                    if(this.search.length) {
                        params['filters'] = {
                            name__icontains: this.search
                        }
                    }

                    const {data} = await this.$http.get('/tasks/task/list/', {params})
                    if(data && data.results.length)
                        this.taskList = this.taskList.concat(data.results)
                    if(!data.next) {
                        if($state)
                            $state.complete()
                        this.scrollStatus = false
                    } else {
                        if($state)
                            $state.loaded()
                    }
                } catch(e) {

                } finally {
                    this.loading = false
                }
            } else {
                if($state)
                    $state.complete()
            }
        },
        reloadTask(item) {
            this.$store.commit('task/SET_TASK', null)
            let query = Object.assign({}, this.$route.query)
            query.task = item.id
            this.$router.push({query})
            this.getTask()
        },
        async getTask() {
            let {task} = Object.assign({}, this.$route.query)
            try {
                await this.$store.dispatch('task/getFullTask', task)
                await this.getCommentsCount()

                const query = JSON.parse(JSON.stringify(this.$route.query))
                // if(query.stab)
                //     this.tab = query.stab
                // else {
                //     if(this.task?.tabs?.length)
                //         this.tab = this.task.tabs[0].code
                //     else
                //         this.tab = 'task'
                // }
            } catch(error) {
                if(error && error.detail && error.detail === 'Не найдено.') {
                    this.$message.warning(this.$t('task.task_not_found'))
                    this.close()
                    this.visible = false
                } else if(error && error.detail && error.detail === 'Страница не найдена.') {
                    this.$message.warning(this.$t('task.task_not_found'))
                    this.close()
                    this.visible = false
                } else {
                    this.$message.error(this.$t('task.error'))
                }
            }
        },
        async getCommentsCount(){
            let {task} = Object.assign({}, this.$route.query)
            let {data} = await this.$http('comments/count/', {params: {related_object: task}})
            this.commentsCount = Number(data) 
        },
    }
}
</script>

<style lang="scss">
.task_select_drawer{
    .ant-drawer-content,
    .ant-drawer-wrapper-body{
        overflow: hidden;
    }
    .ant-drawer-body{
        padding: 0px;
        height: calc(100% - 40px);
    }
    .drawer_header{
        border-bottom: 1px solid var(--borderColor);
        input{
            border-radius: 0px;
            height: 42px;
            border: 0px;
        }
    }
    .drawer_footer{
        border-top: 1px solid var(--borderColor);
        height: 40px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .drawer_body{
        height: calc(100% - 83px);
        .drawer_scroll{
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            .item{
                &:not(:last-child){
                    border-bottom: 1px solid var(--borderColor);
                }
                &:hover{
                    background: var(--hoverBg);
                }
                .name{
                    word-break: break-word;
                }
            }
        }
    }
}
</style>
